<template>
  <div class="editData">
    <el-dialog
      title="详情"
      :visible.sync="dialogVisible"
      width="800px"
      :close-on-click-modal="false"
      @close="close"
    >
     <page-table
      ref="dataTable"
      :data="orderInfo"
    >
      <!-- <el-table-column label="序号" align="center">
        <template slot-scope="scope">
          <span>{{
            (page.currentPage - 1) * page.pageSize + scope.$index + 1
          }}</span>
        </template>
      </el-table-column> -->

      <el-table-column label="是否真实购买" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.device_type == 10">是</span>
          <span v-if="scope.row.device_type == 20">否</span>
        </template>
      </el-table-column>
      <el-table-column label="是否神兽兑换" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.is_exchange == 2">是</span>
          <span v-if="scope.row.is_exchange == 1">否</span>
        </template>
      </el-table-column>
      
      <el-table-column label="推荐人租赁号" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.pid == null">无</span>
          <span v-else>{{ scope.row.pid }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="use_pid" label="推荐人" align="center">
      </el-table-column>
      <el-table-column label="用户团队" align="center" :resizable="false">
        <template slot-scope="scope">
          <el-link v-if="scope.row.user_team == 1">全国</el-link>
          <el-link v-if="scope.row.user_team == 2">大连</el-link>
          <el-link v-if="scope.row.user_team == 3">大庆</el-link>
          <el-link v-if="scope.row.user_team == 4">北京</el-link>
          <el-link v-if="scope.row.user_team == 5">本溪</el-link>
          <el-link v-if="scope.row.user_team == 10">今铭团队</el-link>
          <el-link type="danger" v-if="scope.row.user_team == null"
            >暂无</el-link
          >
        </template></el-table-column
      >
      <el-table-column prop="branch" align="center" label="分支机构"> 
      </el-table-column>
      <el-table-column label="购买区域" align="center" :resizable="false">
        <template slot-scope="scope">
          <el-link v-if="scope.row.box_team == 1">全国</el-link>
          <el-link v-if="scope.row.box_team == 2">大连</el-link>
          <el-link v-if="scope.row.box_team == 3">大庆</el-link>
          <el-link v-if="scope.row.box_team == 4">北京</el-link>
          <el-link v-if="scope.row.box_team == 5">本溪</el-link>
          <el-link type="danger" v-if="scope.row.box_team == null"
            >暂无</el-link
          >
        </template></el-table-column
      >
      <el-table-column label="购买方式" align="center" :resizable="false">
        <template slot-scope="scope">
          <el-link v-if="scope.row.type == 10">APP购买</el-link>
          <el-link v-if="scope.row.type == 20">系统赠送</el-link>
          <el-link v-if="scope.row.type == 30">后台新增</el-link>
          <el-link type="danger" v-if="scope.row.type == null">暂无</el-link>
        </template></el-table-column
      >
    </page-table>
      
    </el-dialog>
  </div>
</template>

<script>
import pageTable from "@/components/pageTable.vue";

export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
     dialogVisible: false,
      orderInfo:[]     
    };
  },
  components: {
    pageTable,
    
  },
  created: function () {},
  mounted () {
  },
  methods: {
    show(rowData) {
        this.orderInfo=[rowData]
         console.log(rowData)
            this.dialogVisible = true
        this.$refs.dataTable.setPageInfo({
            total: this.orderInfo.length,
            });
        
    },
    close() {
        this.orderInfo=[]
        this.dialogVisible = false;
    }   
  },
};
</script>

<style>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.xian {
  margin-left: -75px !important;
}
.userif {
  margin-top: 100px;
}
</style>
