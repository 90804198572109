<template>
  <div>
    <el-dialog
      class="AddDialog"
      title="修改备注"
      :visible.sync="dialogVisible"
      width="1000px"
      hegiht="1000px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
      >
        <el-row :gutter="25">
          <el-col :span="20">
            <el-form-item label="备注">
              <el-input
                v-model="ruleForm.remark"
                style="width: 180px"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { remarkEdit } from "@/request/api";
export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
      dialogVisible: false,

      ruleForm: {
        remark: "",
        id: "",
      },
      rules: {
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
      },
    };
  },

  created() {},
  mounted() {},
  methods: {
    close() {
      this.dialogVisible = false;
      this.ruleForm.id = "";
      this.ruleForm.remark = "";
    },
    show(row) {
      this.dialogVisible = true;
      // console.log(row);
      // console.log(row.id);
      this.ruleForm.id = row.id;
      this.ruleForm.remark = row.remark;
    },
    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let params = {
            id: this.ruleForm.id,
            remark: this.ruleForm.remark,
            token: sessionStorage.getItem("token"),
          };
          remarkEdit(params).then((res) => {
            // console.log(res);
            if (res.data.code == 200) {
              this.$message.success("成功");
              this.$parent.getUserList();
              this.close();
            } else {
              this.$message.error(res.data.msg);
              this.$parent.getUserList();
              this.close();
            }
          });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style>
.xian {
  margin-left: -75px !important;
}
</style>
