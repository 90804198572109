<template>
  <div>
    <el-dialog
      class="AddDialog"
      title="购买套餐"
      v-if="dialogVisible"
      :visible.sync="dialogVisible"
      width="800px"
      hegiht="1200px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-table ref="dataTable" :data="userList" border>
        <el-table-column label="序号" align="center">
          <template slot-scope="scope">
            <span>{{
              (page.currentPage - 1) * page.pageSize + scope.$index + 1
            }}</span>
          </template>
        </el-table-column>

        <el-table-column prop="box_name" label="设备租赁号" align="center">
        </el-table-column>
        <el-table-column label="计划提交日期" align="center">
          <template slot-scope="scope">
            <span> {{ (scope.row.relet_end_time * 1000) | Time }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="updated_at" label="提交时间" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.relet_sta == 3">{{
              scope.row.updated_at
            }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="price" label="支付金额" align="center">
        </el-table-column>
        <el-table-column prop="num" label="还款周期" align="center">
        </el-table-column>
        <el-table-column
          prop="profit_one_uid"
          label="经理人"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="profit_one" label="数量" align="center">
        </el-table-column>
        <el-table-column label="续租确认" align="center">
          <template slot-scope="scope">
            <el-link
              disabled
              type="success"
              :underline="false"
              v-if="scope.row.relet_sta == 3"
              >已续租</el-link
            >
            <el-link
              type="danger"
              @click="reletOk(scope.row)"
              v-if="scope.row.relet_sta == 1"
              >待续租</el-link
            >
            <el-link
              disabled
              type="primary"
              :underline="false"
              v-if="scope.row.relet_sta == 2"
              >待审核</el-link
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script>
import moment from "moment";
import { reletList, reletOk } from "@/request/api";
import pageTable from "@/components/pageTable.vue";
export default {
  name: "AddDialog",
  components: { pageTable },
  data() {
    return {
      box_name: "",
      userList: [],
      dialogVisible: false,
      page: {
        //分页信息
        currentPage: 1, //当前页
        pageSize: 10, //每页条数
        total: 0, //总条数
      },
    };
  },
  filters: {
    Time(e) {
      //处理时间
      return moment(parseInt(e)).format("YYYY-MM-DD HH:mm:ss");
    },
  },
  created() {},
  mounted() {},
  methods: {
    show(row) {
      // if (row.relet_type == 1 || row.relet_type == 23) {
      if (row.relet_type > 0) {
        this.box_name = row.name;
        this.getUserList();
        this.dialogVisible = true;
      }
    },
    close() {
      this.userList=[]
      this.dialogVisible = false;
    },
    reletOk(row) {
      this.$confirm("是否还款？", "提示", {
        type: "warning",
      })
        .then(async () => {
          let params = {
            token: sessionStorage.getItem("token"),
            id: row.id,
          };
          reletOk(params).then((res) => {
            if (res.data.code == 200) {
              this.$message.success("操作成功");
            } else {
              this.$message.dannger(res.data.msg);
            }
            this.getUserList();
          });
        })
        .catch(() => {});
    },

    getUserList() {
      let params = {
        token: sessionStorage.getItem("token"),
        box_name: this.box_name,
      };
      reletList(params).then((res) => {
        this.userList = res.data.data;
        // this.close()
      });
    },
    showtable() {},
  },
};
</script>

<style>
.xian {
  margin-left: -75px !important;
}
</style>
