<template>
  <div>
    <el-dialog
      class="AddDialog"
      title="新增"
      :visible.sync="dialogVisible"
      width="1000px"
      hegiht="1000px"
      :close-on-click-modal="false"
      @close="close"
    >
      <el-form
        :model="ruleForm"
        ref="ruleForm"
        :rules="rules"
        label-width="auto"
      >
        <el-row :gutter="25">
          <el-col :span="20">
            <el-form-item label="购买类型" prop="type">
              <el-select
                v-model="ruleForm.type"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option label="单人购买" value="1"></el-option>
                <el-option label="多人购买" value="2"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="区域选择" prop="box_team">
              <el-select
                v-model="ruleForm.box_team"
                placeholder="请选择"
                style="width: 150px"
                @change="changeValue"
              >
                <el-option label="全国" value="1"></el-option>
                <el-option label="大连" value="2"></el-option>
                <el-option label="大庆" value="3"></el-option>
                <el-option label="北京" value="4"></el-option>
                <el-option label="本溪" value="5"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="推荐人设备租赁号" prop="pid">
              <el-input
                v-model="ruleForm.pid"
                :disabled="isDisable"
                style="width: 180px"
                placeholder="请输入设备租赁号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item class="xian">
              <el-checkbox v-model="check" @change="checked"
                >无推荐人</el-checkbox
              >（外埠市场请选择无推荐人)
            </el-form-item></el-col
          >
          <el-col :span="24" v-if="ruleForm.pid !== 0">
            <el-form-item label="推荐人id" prop="use_pid">
              <el-select
                v-model="ruleForm.use_pid"
                clearable
                style="width: 230px"
                filterable
                placeholder="请选择推荐人id"
              >
                <el-option
                  v-for="item in arr"
                  :value="item.uid"
                  :label="item.uid"
                  :key="item.uid"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="租赁商id" prop="uid" v-if="ruleForm.type == 1">
              <el-input
                v-model="ruleForm.uid"
                style="width: 180px"
                placeholder="请输入租赁人用户id"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item
              label="租赁人手机号"
              prop="tel"
              v-if="ruleForm.type == 1"
            >
              <el-input
                v-model="ruleForm.tel"
                style="width: 180px"
                placeholder="请输入租赁人手机号"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col v-if="ruleForm.type == 2" style="margin-left: -57px">
            <el-form-item v-for="(v, k) in list" :key="k">
              <span>账号{{ k + 1 }}</span>
              租赁商id:
              <el-input
                v-model="v.uid"
                style="width: 100px"
                placeholder="请输入"
              ></el-input>
              分润占比:
              <el-select
                v-model="v.bl"
                placeholder="请选择"
                style="width: 100px"
              >
                <el-option label="12.5%" value="12.5"></el-option>
                <el-option label="25%" value="25"></el-option>
                <el-option label="50%" value="50"></el-option>
              </el-select>

              手机号:
              <el-input
                @blur="checkRight(v)"
                v-model="v.tel"
                style="width: 150px"
                placeholder="请输入"
              ></el-input>
              <el-button
                type="primary"
                style="margin-left: 10px"
                size="mini"
                v-if="k == list.length - 1 && k <= 6"
                @click="insert"
                >+</el-button
              >
              <el-button
                type="danger"
                size="mini"
                v-if="k !== 1 && k == list.length - 1"
                @click="remove(k)"
                >-</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="购买服务" prop="box_type">
              <el-select
                v-model="ruleForm.box_type"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option label="三年" value="1"></el-option>
                <el-option label="五年" value="2"></el-option>
                <el-option label="终身" value="3"></el-option>
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="10">
            <el-form-item label="是否真实购买" prop="device_type">
              <el-select
                v-model="ruleForm.device_type"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option label="是" value="10"></el-option>
                <el-option label="否" value="20"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" v-if="ruleForm.type == 1">
            <el-form-item label="租赁套餐" prop="relet_type">
              <el-select
                v-model="ruleForm.relet_type"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option label="否" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="10" v-if="ruleForm.type == 1">
            <el-form-item label="是否兑换" prop="is_exchange">
              <el-select
                v-model="ruleForm.is_exchange"
                placeholder="请选择"
                style="width: 150px"
              >
                <el-option
                  v-if="ruleForm.relet_type == 0"
                  label="是"
                  value="2"
                ></el-option>
                <el-option label="否" value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col
            v-if="ruleForm.relet_type == 1 && ruleForm.type == 1"
            style="margin-left: -57px"
          >
            <el-form-item v-for="(v, k) in paylist" :key="k">
              <el-radio v-model="radio" :label="v.id" v-if="v.id">
                <span>套餐{{ k + 1 }}:</span>
              </el-radio>
              <span v-if="!v.id" style="margin-left: 20px">待确认套餐:</span>
              首付
              <el-input v-model="v.payAll" style="width: 90px"></el-input>元,
              <el-input v-model="v.num" style="width: 60px"></el-input>
              期补齐, 每期
              <el-input v-model="v.payOne" style="width: 90px"></el-input>
              元
              <el-button
                type="primary"
                style="margin-left: 10px"
                size="mini"
                v-if="!v.id"
                @click="submitpay(v)"
                >确认</el-button
              >
              <el-button
                type="primary"
                style="margin-left: 10px"
                size="mini"
                v-if="k == paylist.length - 1 && v.id"
                @click="addPay"
                >+</el-button
              >
              <el-button
                type="danger"
                size="mini"
                v-if="k !== 0"
                @click="delPay(v, k)"
                >-</el-button
              >
            </el-form-item>
          </el-col>
          <el-col :span="20">
            <el-form-item label="备注">
              <el-input
                v-model="ruleForm.remark"
                style="width: 180px"
                placeholder="请输入备注"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitForm">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  addbox,
  doctoradd,
  reletType,
  goodseditshow,
  goodsedit,
  reletTypedel,
  reletTypeadd,
  chooseboxuid,
} from "@/request/api";
export default {
  name: "AddDialog",
  components: {},
  data() {
    return {
      id: "",
      check: false,
      token: "",
      arr: [],
      relet: "",
      radio: "",
      paylist: [],
      uid: "",
      bl: "",
      kshow: true,
      tel: "",
      isDisable: false,
      type: "", //1新增，2编辑
      dialogVisible: false,
      list: [
        { uid: "", tel: "", bl: "" },
        { uid: "", tel: "", bl: "" },
      ],
      user: { uid: "", tel: "", bl: "" },
      pay: { payAll: "", num: "", payOne: "" },
      ruleForm: {
        uid: "",
        tel: "",
        use_pid: "",
        type: "1",
        remark: "",
        box_type: "2",
        device_type: "10",
        relet_type: "0",
        is_exchange: "1",
        pid: "",
        box_team: "",
      },
      rules: {
        type: [{ required: true, message: "请选择购买类型", trigger: "blur" }],
        use_pid: [
          { required: true, message: "请选择推荐人id", trigger: "blur" },
        ],
        remark: [{ required: true, message: "请输入备注", trigger: "blur" }],
        box_team: [{ required: true, message: "请选择地区", trigger: "blur" }],
        uid: [
          { required: true, message: "请输入租赁人用户id", trigger: "blur" },
        ],
        tel: [
          { required: true, message: "请输入租赁人手机号", trigger: "blur" },
          {
            pattern: /^1[3456789]\d{9}$/,
            message: "手机号格式不正确",
            trigger: "blur",
          },
        ],
        box_type: [
          { required: true, message: "请选择购买服务", trigger: "blur" },
        ],
        device_type: [
          { required: true, message: "请选择是否真实购买", trigger: "blur" },
        ],
        relet_type: [
          { required: true, message: "请选择套餐", trigger: "blur" },
        ],
        is_exchange: [
          { required: true, message: "请选择是否兑换", trigger: "blur" },
        ],
        pid: [{ required: true, message: "请输入设备租赁号", trigger: "blur" }],
      },
    };
  },
  watch: {
    "ruleForm.pid"(newVal) {
      this.huoopin();
    },
    "ruleForm.relet_type"(value) {
      if (this.selectValue == 4) {
        this.radio = 23;
      } else {
        this.radio = 1;
      }
      this.ruleForm.is_exchange = "1";
    },
  },
  created() {
    this.getpaylist();
  },
  mounted() {},
  methods: {
    addPay() {
      // console.log("加");
      this.pay = { payAll: "", num: "", payOne: "" };
      this.paylist.push(this.pay);
      // console.log(this.paylist);
    },
    submitpay(v) {
      // console.log(v);
      if (!v.payAll) {
        this.$message.error("首付不能为空");
        return;
      }
      if (!v.payOne) {
        this.$message.error("每期金额不能为空");
        return;
      }
      if (!v.num) {
        this.$message.error("期数不能为空");
        return;
      }
      let params = {
        payAll: v.payAll,
        payOne: v.payOne,
        num: v.num,
        token: sessionStorage.getItem("token"),
      };
      reletTypeadd(params).then((res) => {
        if (res.data.code == 200) {
          this.$message.success("新增成功");
          this.getpaylist();
        } else {
          this.$message.error(res.data.msg);
          this.getpaylist();
        }
      });
    },
    delPay(v, k) {
      // console.log(v);
      // console.log(k);
      if (v.id) {
        let params = {
          token: sessionStorage.getItem("token"),
          id: v.id,
        };
        reletTypedel(params).then((res) => {
          if (res.data.code == 200) {
            this.$message.success("成功");
            this.getpaylist();
          } else {
            this.$message.error(res.data.msg);
            this.getpaylist();
          }
        });
      } else {
        this.paylist.splice(k, 1);
      }
    },
    getpaylist() {
      let params = {
        token: sessionStorage.getItem("token"),
      };
      reletType(params).then((res) => {
        // console.log(res);
        // console.log(res.data.data);
        this.paylist = res.data.data;
      });
    },
    checkRight(v) {
      const tel = v.tel;
      var reg = /^1[3456789]\d{9}$/;
      if (reg.test(tel) == false) {
        this.$message.error("手机号错误");
      }
    },
    insert() {
      this.user = { uid: "", tel: "", bl: "" };
      this.list.push(this.user);
    },
    remove(index) {
      this.list.splice(index, 1);
    },
    huoopin() {
      let params = {
        token: sessionStorage.getItem("token"),
        name: this.ruleForm.pid,
      };
      chooseboxuid(params).then((res) => {
        this.arr = res.data.data;
      });
    },
    show(type, row) {
      this.dialogVisible = true;
    },
    close() {
      this.dialogVisible = false;
      this.ruleForm.pid = "";
      this.ruleForm.use_pid = "";
      this.ruleForm.remark = "";
      this.ruleForm.tel = "";
      this.ruleForm.box_team = "";
      this.ruleForm.box_type = "2";
      this.list = [
        { uid: "", tel: "", bl: "" },
        { uid: "", tel: "", bl: "" },
      ];
      this.ruleForm.uid = "";
      this.ruleForm.type = "1";
      this.ruleForm.device_type = "10";
      this.ruleForm.relet_type = "0";
      this.ruleForm.is_exchange = "1";
      this.isDisable = false;

      this.check = false;
    },
    checked(events) {
      this.events = events;
      if (events == true) {
        this.isDisable = true;
        this.ruleForm.pid = 0;
      } else {
        this.isDisable = false;
        this.ruleForm.pid = "";
      }
    },
    go() {
      this.$router.back();
    },

    submitForm() {
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          if (this.ruleForm.type == 1) {
            //单人
            if (this.ruleForm.relet_type == 0) {
              this.relet = 0;
            } else {
              this.relet = this.radio;
            }
            // console.log(this.relet);
            let params = {
              token: sessionStorage.getItem("token"),
              uid: this.ruleForm.uid,
              remark: this.ruleForm.remark,
              tel: this.ruleForm.tel,
              box_type: this.ruleForm.box_type,
              pid: this.ruleForm.pid,
              use_pid: this.ruleForm.use_pid,
              device_type: this.ruleForm.device_type,
              relet_type: this.relet,
              is_exchange: this.ruleForm.is_exchange,
              box_team: this.ruleForm.box_team,
              str: this.ruleForm.uid
                .concat(",")
                .concat(this.ruleForm.tel)
                .concat(",")
                .concat(100),
            };
            addbox(params).then((res) => {
              if (res.data.code == 200) {
                this.$message.success("新增成功");
                this.$parent.getUserList();
                this.close();

                this.isDisable = false;
                this.check = false;
              } else {
                this.$message.error(res.data.msg);
                this.$parent.getUserList();
                this.close();
                this.isDisable = false;
                this.check = false;
              }
            });
          } else {
            //多人
            var that = this;
            let flag = that.list.every((item) => !!item.uid);
            let flag1 = that.list.every((item) => !!item.tel);
            let flag2 = that.list.every((item) => !!item.bl);
            if (!flag) {
              this.$message.error("租赁商id不能为空");
              return;
            }
            if (!flag1) {
              this.$message.error("手机号不能为空");
              return;
            }
            if (!flag2) {
              this.$message.error("分润占比不能为空");
              return;
            }

            //定义 w
            let sum = 0;
            this.list.forEach((item) => {
              //遍历prodAllPrice这个字段，并累加
              sum += Number(item.bl);
            });
            //返回

            if (sum === 100) {
              let res = "";
              for (let i = 0; i < this.list.length; i++) {
                res +=
                  this.list[i].uid +
                  "," +
                  this.list[i].tel +
                  "," +
                  this.list[i].bl +
                  "&&";
              }
              const str = res.substring(0, res.length - 2);
              let params = {
                token: sessionStorage.getItem("token"),
                uid: this.list[0].uid,
                tel: this.list[0].tel,
                box_type: this.ruleForm.box_type,
                pid: this.ruleForm.pid,
                remark: this.ruleForm.remark,
                use_pid: this.ruleForm.use_pid,
                device_type: this.ruleForm.device_type,
                box_team: this.ruleForm.box_team,
                str: str,
              };
              addbox(params).then((res) => {
                if (res.data.code == 200) {
                  this.$message.success("新增成功");
                  this.$parent.getUserList();
                  this.close();
                  this.isDisable = false;
                  this.check = false;
                } else {
                  this.$message.error(res.data.msg);
                  this.$parent.getUserList();
                  this.close();

                  this.isDisable = false;
                  this.check = false;
                }
              });
            } else if (sum <= 100) {
              this.$message.error("分润占比总和不能小于100%");
            } else {
              this.$message.error("分润占比总和不能超过100%");
            }
          }
        } else {
          return false;
        }
      });
    },
    changeValue(value) {
      this.selectValue = value;
    },
  },
};
</script>

<style>
.xian {
  margin-left: -75px !important;
}
</style>
